@import "./cardsAnimation.scss";
@import "../../themes/variables.scss";

.App {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  background: $bgg-violet-blue;
  box-shadow: 0px 20px 68px #12237b;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  font-family: $ff-regular;
  -webkit-overflow-scrolling: touch;

  &_Step {
    &_Round {
      position: initial;
      overflow: initial;
      justify-content: flex-start;
      background: $bgg-black-blue;
    }

    &_EnterRoom {
      .App__Title {
        animation: title-slide-top 400ms forwards ease-in-out;
      }

      .App__Card {
        &:nth-child(1) {
          animation: scale-1st-top 400ms forwards ease-in-out;
        }

        &:nth-child(2) {
          animation: scale-2nd-bottom 400ms forwards ease-in-out;
        }

        &:nth-child(3) {
          animation: scale-3rd-top 400ms forwards ease-in-out;
        }

        &:nth-child(4) {
          animation: scale-4th-bottom 400ms forwards ease-in-out;
        }
      }
    }

    &_EnterName {
      background: $bgg-black-blue;
      box-shadow: 0px 12.6875px 43px $color-deep-blue;

      .App__Title {
        animation: title-fade-out 400ms forwards ease-in-out;
      }

      .App__Card {
        &:nth-child(1) {
          animation: hide-1st-top 500ms forwards ease-in-out;
        }

        &:nth-child(2) {
          animation: hide-2nd-bottom 500ms forwards ease-in-out;
        }

        &:nth-child(3) {
          animation: hide-3rd-top 500ms forwards ease-in-out;
        }

        &:nth-child(4) {
          animation: hide-4th-bottom 500ms forwards ease-in-out;
        }
      }
    }
  }

  &__CardsContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__Card {
    position: absolute;
    top: 50%;
    width: 75px;
    height: 110px;
    border-radius: 18px;
    will-change: transform, width, height;

    &:nth-child(1) {
      z-index: 1;
      top: 50%;
      transform: rotate(-10deg);
      background: $color-pink;
      box-shadow: inset 0px 0.35px 0.23px $color-light-gray;
      animation: show-1st-card 700ms forwards ease-in-out;
    }

    &:nth-child(2) {
      z-index: 1;
      transform: rotate(30deg);
      opacity: 0.7;
      background: $color-medium-purple;
      box-shadow: inset 0px 0.35px 0.23px $color-light-gray;
      animation: show-2nd-card 700ms forwards ease-in-out;
    }

    &:nth-child(3) {
      z-index: 0;
      transform: rotate(-9deg);
      opacity: 0.9;
      background: $color-tangerine-yellow;
      box-shadow: inset 0px 0.35px 0.23px $color-light-gray;
      animation: show-3rd-card 700ms forwards ease-in-out;
    }

    &:nth-child(4) {
      z-index: 1;
      transform: rotate(14deg);
      opacity: 0.9;
      background: $color-sky-blue;
      box-shadow: inset 0px 0.345376px 0.230251px $color-light-gray;
      animation: show-4th-card 700ms forwards ease-in-out;
    }
  }

  &__Title {
    color: #fff;
    font-size: 64px;
    font-family: $ff-black;
    letter-spacing: 2px;
    z-index: 2;
    position: relative;
    animation: zoom-in 700ms forwards ease-in-out;
  }

  &__StartStep {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background: $bgg-violet-blue;
    box-shadow: 0px 20px 68px $color-deep-blue;
  }

  &__Logo {
    width: 265px;
    height: 130px;
    background-image: url(../../assets/images/logo.png);
  }

  &__ExitButton {
    position: absolute;
    left: 20px;
    top: 20px;
    z-index: 3;
  }

  &__Version {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
    color: #fff;
    opacity: .5;
    font-family: $ff-black;
  }
}
