@font-face {
  font-family: "SF-Pro-Rounded-Regular";
  src: url('//s9.vcdn.biz/static/93833061/SF-Pro-Rounded-Regular.eot'); /* IE9 Compat Modes */
  src: url('//s6.vcdn.biz/static/93833061/SF-Pro-Rounded-Regular.otf')   format('opentype'), /* Open Type Font */
       url('//s5.vcdn.biz/static/93833061/SF-Pro-Rounded-Regular.woff')  format('woff'), /* Modern Browsers */
       url('//s6.vcdn.biz/static/93833061/SF-Pro-Rounded-Regular.woff2') format('woff2'); /* Modern Browsers */
  font-weight: 400;
}

@font-face {
  font-family: "SF-Pro-Rounded-Bold";
  src: url('//s5.vcdn.biz/static/93833061/SF-Pro-Rounded-Bold.eot'); /* IE9 Compat Modes */
  src: url('//s6.vcdn.biz/static/93833061/SF-Pro-Rounded-Bold.otf') format('opentype'), /* Open Type Font */
        url('//s1.vcdn.biz/static/93833061/SF-Pro-Rounded-Bold.woff') format('woff'), /* Modern Browsers */
        url('//s8.vcdn.biz/static/93833061/SF-Pro-Rounded-Bold.woff2') format('woff2'); /* Modern Browsers */
  font-weight: 600;
}

@font-face {
  font-family: "SF-Pro-Rounded-Black";
  src: url('//s5.vcdn.biz/static/93833061/SF-Pro-Rounded-Black.eot'); /* IE9 Compat Modes */
  src: url('//s3.vcdn.biz/static/93833061/SF-Pro-Rounded-Black.otf') format('opentype'), /* Open Type Font */
       url('//s6.vcdn.biz/static/93833061/SF-Pro-Rounded-Black.woff') format('woff'), /* Modern Browsers */
       url('//s9.vcdn.biz/static/93833061/SF-Pro-Rounded-Black.woff2') format('woff2'); /* Modern Browsers */
  font-weight: 900;
}
