@import "../../themes/variables.scss";
@import "../../themes/animation.scss";

.NameInput {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1;
  animation: fade-in 500ms forwards ease-in-out;

  &__Title {
    padding: 0 5px;
    margin-bottom: 65px;
  }

  &__Field {
    width: 255px;
    padding-bottom: 10px;
    margin-bottom: 50px;
    font-family: $ff-bold;
    font-size: 36px;
    text-align: center;
    color: #fff;
    background-color: transparent;
    border-bottom: 2px solid #fff;
  }

  &__Submit {
    background-color: transparent;
    border: 0;
    border-radius: 50%;
    
    .Button {
      border-radius: 50%;
      width: 80px;
      min-width: 80px;
      height: 80px;
      padding: 0;
    }
  }
}
