@import "../../themes/variables.scss";

.ExitPopup {
  &__Overlay {
    position: fixed;
    z-index: 5;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba($color: #000000, $alpha: 0.7);
    animation: fade-in 300ms ease-in-out forwards;
  }

  &__Wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 315px;
    height: 492px;
    box-sizing: border-box;
    padding: 50px 40px;
    border-radius: 32px;
    background-color: $color-dark-violet;

    &_State {
      &_Hidden {
        display: none;
      }
    }
  }

  &__Text {
    flex: 3;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    align-items: center;
    text-align: center;
    color: #fff;
    font-family: $ff-black;
    font-weight: 900;
    font-size: 36px;
    line-height: 40px;
  }

  &__Controls {
    flex: 1;

    .Button {
      width: 235px;

      &:first-child {
        margin-bottom: 20px;
      }
    }
  }
}

@media screen and (max-width: $media_width_xs) {
  .ExitPopup {
    &__Wrapper {
      width: 280px;
      height: 450px;
    }

    &__Controls .Button {
      width: 245px;
    }
  }
}
