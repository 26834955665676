@import "./variables.scss";

.Title {
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  color: #fff;
}

.Button {
  min-width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  box-sizing: border-box;
  color: #fff;
  border: 4px solid #fff;
  border-radius: 20px;
  background-color: $color-blue-zodiac;
  outline: none;
  font-size: 28px;
  font-family: $ff-black;
  font-weight: 900;
  text-transform: uppercase;

  // now on ios box-shadow looks so sad 
  @supports not (-webkit-touch-callout: none) {
    box-shadow: 0px 20px 68px 0px rgba(0, 0, 0, 0.6);
  }
}

.WinnerConfetti {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  pointer-events: none;
}

.Overflow {
  &_Hidden {
    overflow: hidden;
  }
}
