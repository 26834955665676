@import "../../themes/variables.scss";

.Cards {
  margin-bottom: 30px;

  &::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: $bgg-black-blue;
    z-index: 5;
    visibility: hidden;
    opacity: 0;
    transition: visibility 400ms, ease-out opacity 400ms;
    overflow: hidden;
  }

  &_State {
    &_Covered {
      position: relative;

      &::before {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  &__Wrapper {
    animation: slide-up 700ms forwards ease-in-out;

    &_State {
      &_Selected {
        position: fixed;
        left: 0;
        top: 0;
        z-index: 10;
      }
    }

    &_State {
      &_Submited {
        .Cards__ItemWrapper_State_Selected {
          .Cards__Item {
            animation: slide-up-out 500ms forwards ease-in-out;
          }
        }
      }
    }
  }

  &__ItemWrapper {
    &_State {
      &_Selected {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        padding-bottom: 30px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        z-index: 10;
        background: $bgg-black-blue;
      }
    }

    &:nth-child(2n) {
      .Cards__Item {
        transform: rotate(1deg);
      }
    }

    &:not(:last-child) {
      margin-bottom: 25px;
    }
  }

  &__Item {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 16px;
    background-color: rgba($color: $color-yellow, $alpha: 0.7);
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.4);
    transform: rotate(-1deg);

    &_State {
      &_Loaded {
        background-color: initial;
      }
    }
  }

  &__ItemImg {
    width: 0;
    height: 0;
    opacity: 0;
  }

  &__ItemSkin {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    background-size: 104%;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0;
    transition: opacity 300ms ease-in-out;
    transform: translateZ(1);

    &_State {
      &_Loaded {
        opacity: 1;
      }
    }
  }

  &__SelectedSkin {
    animation: scale-in 400ms forwards ease-in-out;
  }

  &__SelectedControls {
    margin-top: 30px;

    svg {
      animation: scale-from-zero 400ms forwards ease-in-out;

      &:first-child {
        margin-right: 40px;
      }
    }

    &_State {
      &_Submited {
        svg {
          animation: scale-out 400ms forwards ease-in-out;
        }
      }
    }
  }
}

@keyframes card-left-drop {
  0% {
    position: fixed;
    top: 50px;
    transform: rotate(-1deg) translateY(150vh);
  }
  50% {
    position: fixed;
    top: 50px;
    transform: rotate(-1deg) translateY(20vh);
  }
  100% {
    transform: rotate(-1deg) translateY(0);
  }
}

@media screen and (min-width: $media_width_m) {
  .Cards {
    &__Item {
      width: 415px;
      height: 620px;
    }
  }
}

@media screen and (max-width: $media_width_m) {
  .Cards {
    &__Item {
      width: 390px;
      height: 580px;
    }
  }
}

@media screen and (max-width: $media_width_s) {
  .Cards {
    &__Item {
      width: 350px;
      height: 520px;
    }
  }
}

@media screen and (max-width: $media_width_xs) {
  .Cards {
    &__Item {
      width: 310px;
      height: 460px;
    }
  }
}

@media screen and (max-width: $media_width_xxs) {
  .Cards {
    &__Item {
      width: 260px;
      height: 390px;
    }
  }
}

@media screen and (max-width: $media_width_xxxs) {
  .Cards {
    &__ItemWrapper {
      &_State {
        &_Selected {
          .Cards__Item {
            width: 220px;
            height: 310px;
          }
        }
      }
    }
  }
}
