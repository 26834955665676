@import "../../themes/variables.scss";

@keyframes show-1st-card {
  0% {
    transform: rotate(-10deg) translate(-200%, 0);
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: rotate(-10deg) translate(-100%, -60%);
  }
}

@keyframes show-2nd-card {
  0% {
    opacity: 0;
    transform: rotate(30deg) translate(0, 200%);
  }

  100% {
    opacity: 1;
    transform: rotate(30deg) translate(-75%, -30%);
  }
}

@keyframes show-3rd-card {
  0% {
    opacity: 0;
    transform: rotate(-9deg) translate(0, -200%);
  }

  100% {
    opacity: 1;
    transform: rotate(-9deg) translate(40%, -45%);
  }
}

@keyframes show-4th-card {
  0% {
    opacity: 0;
    transform: rotate(14deg) translate(200%, 0);
  }

  100% {
    opacity: 1;
    transform: rotate(14deg) translate(90%, -65%);
  }
}

@keyframes scale-1st-top {
  100% {
    top: 0;
    transform: rotate(-10deg) scale(5) translate(-70%, 10%);
  }
}

@keyframes scale-2nd-bottom {
  100% {
    top: 0;
    transform: rotate(15deg) scale(5) translate(-40%, 110%);
  }
}

@keyframes scale-3rd-top {
  100% {
    top: 0;
    transform: rotate(10deg) scale(5) translate(70%, 0%);
  }
}

@keyframes scale-4th-bottom {
  100% {
    top: 0;
    transform: rotate(-15deg) scale(5) translate(40%, 110%);
  }
}

@keyframes hide-1st-top {
  0% {
    top: 0;
    transform: rotate(-10deg) scale(5) translate(-70%, 10%);
  }

  100% {
    transform: rotate(-10deg) scale(5) translate(-170%, -110%);
  }
}

@keyframes hide-2nd-bottom {
  0% {
    top: 0;
    transform: rotate(15deg) scale(5) translate(-40%, 110%);
  }

  100% {
    transform: rotate(15deg) scale(5) translate(-140%, 210%);
  }
}

@keyframes hide-3rd-top {
  0% {
    top: 0;
    transform: rotate(10deg) scale(5) translate(70%, 0%);
  }

  100% {
    transform: rotate(10deg) scale(5) translate(170%, -100%);
  }
}

@keyframes hide-4th-bottom {
  0% {
    top: 0;
    transform: rotate(-15deg) scale(5) translate(40%, 110%);
  }

  100% {
    transform: rotate(-15deg) scale(5) translate(140%, 210%);
  }
}

@media screen and (min-width: $media_width_m) {
  @keyframes scale-1st-top {
    100% {
      top: 0;
      transform: rotate(-10deg) scale(5) translate(-90%, 10%);
    }
  }

  @keyframes scale-2nd-bottom {
    100% {
      top: 0;
      transform: rotate(15deg) scale(5) translate(-50%, 140%);
    }
  }

  @keyframes scale-3rd-top {
    100% {
      top: 0;
      transform: rotate(10deg) scale(5) translate(100%, 10%);
    }
  }

  @keyframes scale-4th-bottom {
    100% {
      top: 0;
      transform: rotate(-15deg) scale(5) translate(50%, 140%);
    }
  }

  @keyframes hide-1st-top {
    0% {
      top: 0;
      transform: rotate(-10deg) scale(5) translate(-90%, 10%);
    }

    100% {
      transform: rotate(-10deg) scale(5) translate(-300%, -110%);
    }
  }

  @keyframes hide-2nd-bottom {
    0% {
      top: 0;
      transform: rotate(15deg) scale(5) translate(-50%, 140%);
    }

    100% {
      transform: rotate(15deg) scale(5) translate(-140%, 210%);
    }
  }

  @keyframes hide-3rd-top {
    0% {
      top: 0;
      transform: rotate(10deg) scale(5) translate(100%, 10%);
    }

    100% {
      transform: rotate(10deg) scale(5) translate(280%, -100%);
    }
  }

  @keyframes hide-4th-bottom {
    0% {
      top: 0;
      transform: rotate(-15deg) scale(5) translate(50%, 140%);
    }

    100% {
      transform: rotate(-15deg) scale(5) translate(140%, 210%);
    }
  }
}
