@import "../../themes/animation.scss";
@import "../../themes/variables.scss";

.Loader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  
  svg {
    width: 80px;
    height: 80px;
    animation: rotation 900ms infinite ease-in-out;
  }
}
