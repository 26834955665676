// FONTS
$ff-regular: "SF-Pro-Rounded-Regular", Arial, sans-serif;
$ff-bold: "SF-Pro-Rounded-Bold", Arial, sans-serif;
$ff-black: "SF-Pro-Rounded-Black", Arial, sans-serif;

// BACKGROUNDS GRADIENT
$bgg-violet-blue: linear-gradient(70deg, #12237b 30%, #196bcb 100%);
$bgg-black-blue: linear-gradient(
    261.69deg,
    rgba(0, 0, 0, 0.2) 0%,
    rgba(0, 0, 0, 0) 100%
  ),
  #387cad;

// COLORS
$color-yellow: #dfc00c;
$color-tangerine-yellow: #fa7719;
$color-deep-blue: #12237b;
$color-pink: #d51f67;
$color-light-gray: rgba(255, 255, 255, 0.2);
$color-medium-purple: #a24fdf;
$color-dark-violet: #1B49A4;
$color-sky-blue: #00a4ff;
$color-blue-zodiac: #0F1741;

// MEDIA
$media-width-l: 1024px;
$media-width-m: 720px;
$media-width-s: 540px;
$media-width-xs: 420px;
$media-width-xxs: 360px;
$media-width-xxxs: 340px;

$media-height-l: 720px;
$media-height-m: 680px;
$media-height-s: 580px;
$media-height-xs: 480px;
$media-height-xxs: 420px;
