@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes zoom-in {
  0% {
    opacity: 0;
  }

  40% {
    opacity: 0;
    transform: scale(4);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes scale-in {
  0% {
    transform: scale(0.8);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes scale-out {
  100% {
    transform: scale(0);
  }
}

@keyframes scale-from-zero {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes title-fade-out {
  0% {
    opacity: 1;
    transform: translate(0, -125%);
  }

  100% {
    opacity: 0;
    transform: translate(0, -125%);
  }
}

@keyframes title-slide-top {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(0, -100%);
  }
}

@keyframes height-in {
  0% {
    height: 0;
    max-height: 0;
  }

  100% {
    max-height: 80px;
    height: 80px;
  }
}

@keyframes height-out {
  0% {
    max-height: 80px;
    height: 80px;
  }

  95% {
    opacity: 1;
  }

  100% {
    height: 0;
    max-height: 0;
    opacity: 0;
  }
}

@keyframes show-inputs {
  0% {
    height: 0;
    max-height: 0;
  }

  100% {
    max-height: 80px;
    height: 80px;
  }
}

@keyframes slide-up {
  0% {
    transform: translateY(110vh);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slide-up-out {
  0% {
    transform: translateY(0);
  }
  
  100% {
    transform: translateY(-110vh);
  }
}

@keyframes rotate-in {
  0% {
    opacity: 0;
    transform: rotate(-180deg);
  }

  100% {
    opacity: 1;
    transform: rotate(0);
  }
}

@keyframes rotation {
  0% {
    transform-origin: center;
    transform: rotate(0deg);
  }
  100% {
    transform-origin: center;
    transform: rotate(360deg);
  }
}

@keyframes shake-left-right {
  20% {
    transform: translateX(-10%);
  }

  40% {
    transform: translateX(10%);
  }

  60% {
    transform: translateX(-10%);
  }

  80% {
    transform: translateX(10%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes scale-bound {
  70% {
    transform: scale(1.15);
  }

  100% {
    transform: scale(1);
  }
}
