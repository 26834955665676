.PlayerIcon {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  display: inline-block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 65px;
}
