@import "../../themes/variables.scss";
@import "../../themes/animation.scss";

.GameCodeInput {
  z-index: 1;

  &__Title {
    max-width: 270px;
    margin: 0 auto 50px;
    font-family: $ff-bold;
    font-size: 24px;
    text-align: center;
    color: #ffffff;
    animation: scale-in 500ms ease-in-out forwards;
  }

  &__Fields {
    height: 100px;
    display: flex;
    align-items: flex-end;

    &_State {
      &_ClearValues {
        animation: shake-left-right 400ms ease-in-out forwards;
      }
    }
  }

  &__Field {
    animation: height-in 400ms ease-in-out forwards;
    width: 60px;
    background: #0f1741;
    border: 0;
    border-radius: 16px;
    color: #fff;
    font-family: $ff-bold;
    font-size: 48px;
    text-align: center;
    box-sizing: border-box;
    padding: 10px;

    &:not(:last-child) {
      margin-right: 15px;
    }

    &_State {
      &_Success {
        animation: height-out 400ms ease-in-out forwards;
      }
    }
  }

  &__LoaderContainer {
    position: absolute;
    width: 80px;
    height: 80px;
    left: 50%;
    bottom: 10%;
    transform: translateX(-50%);
  }
}
