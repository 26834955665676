@import "../../themes/variables.scss";

.Round {
  width: 100%;
  min-height: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  color: #fff;
  font-family: $ff-bold;
  overflow-y: scroll;

  &__Header {
    text-align: center;
    margin: 5vh auto;
  }

  &__HeaderTitle {
    margin-top: 20px;
    padding: 0 10%;
    font-size: 36px;
    animation: scale-from-zero 500ms forwards ease-in-out;
  }

  &__Content {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
  }

  &__Result {
    padding: 20px 0;
    font-size: 144px;
    animation: scale-from-zero 500ms forwards ease-in-out;
  }

  &__Result,
  &__LoaderContainer,
  &__Finished {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__Finished {
    flex-direction: column;

    .Button {
      margin-bottom: 40px;
    }
  }

  &__WinnerResult {
    margin-bottom: 40px;
    text-align: center;
    font-size: 144px;
  }

  &__ResultTitle,
  &__WinnerTitle {
    text-align: center;
    font-size: 64px;
  }

  &__WinnerTitle {
    margin-bottom: 20px;
    white-space: pre-wrap;
  }

  &__ControlButton {
    margin-bottom: 50px;

    &_State {
      &_Clicked {
        animation: scale-bound 300ms ease-in-out;
      }
    }
  }

  &__ContentTitle {
    padding: 0 10%;
    text-align: center;
    font-size: 36px;
    white-space: pre-wrap;
    animation: scale-from-zero 500ms forwards ease-in-out;
  }

  &__TimerContainer {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 180px;
    width: 100%;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.7) 100%
    );
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 70px;
  }

  &__Timer {
    width: 90px;
    height: 90px;
    position: relative;

    svg {
      position: absolute;
    }

    span {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      font-family: $ff-black;
      font-size: 32px;
      line-height: 90px;
      color: #fff;
    }
  }

  &__MissedMove {
    position: fixed;
    z-index: 5;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba($color: #000000, $alpha: 0.7);
    animation: fade-in 300ms ease-in-out forwards;
  }

  &__MissedMovePopup {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 315px;
    height: 492px;
    box-sizing: border-box;
    padding: 50px 40px;
    border-radius: 32px;
    background-color: $color-dark-violet;
  }

  &__MissedMoveText {
    flex: 3;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    align-items: center;
    text-align: center;
    color: #fff;
    font-family: $ff-black;
    font-weight: 900;
    font-size: 36px;
    line-height: 40px;
  }

  &__WaitAssociationTitle {
    max-width: 280px;
    white-space: initial;
  }

  &__MasterName {
    max-width: 280px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &__ContentLoader {
    text-align: center;

    .Loader {
      margin-bottom: 20px;
    }
  }

  &__ContentLoader {
    text-align: center;

    .Loader {
      margin-bottom: 20px;
    }
  }
}

@media screen and (max-width: $media_width_m) {
  .Round {
    &__WinnerResult {
      font-size: 112px;
    }
    &__WinnerTitle {
      font-size: 42px;
    }
  }
}

@media screen and (max-width: $media_width_xs) {
  .Round {
    &__MissedMovePopup {
      width: 280px;
      height: initial;
      max-height: 450px;

      .Button {
        width: 245px;
      }
    }

    &__MissedMoveText {
      font-size: 32px;
    }
  }
}

@media screen and (max-width: $media_width_xxs) {
  .Round {
    &__TimerContainer {
      padding-bottom: 30px;
    }

    &__WinnerTitle {
      font-size: 36px;
    }
  }
}
